import { Environment } from "./evironment.interface";

export const environment: Environment = {
    VERSION: "1.343.0",
    production: true,
    environment: 'production',
    afterAuthPath: '/checkaccess',
    firebase: {
        apiKey: "AIzaSyBOf9T-SFn9Naz37KfA9tu_1vSDRz7MFUA",
        authDomain: "core-6p22-ukprod-658c405b.firebaseapp.com",
        projectId: "core-6p22-ukprod-658c405b",
        storageBucket: "core-6p22-ukprod-658c405b.appspot.com",
        messagingSenderId: "262565461276",
        appId: "1:262565461276:web:50ef141aa2d08168154ed9",
        measurementId: ""
    },
    stripe: "pk_live_4uixpExroXw0z9euXXsNOM0u",
    formIoRootUrl: "https://form-io.joiipetcare.com",
    tokboxAPIKey: "46402962",
    defaulttenant: "vetai",
    providerswitcher: false,
    tenants: [{
        name: "Vet-AI",
        icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9",
        image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fsmall-bw-cat.jpg?alt=media&token=ade5337d-fcdd-41da-9ac8-78cdec4b1bc2",
        pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Flarge-bw-cat.jpg.jpg?alt=media&token=06aea469-62a7-4e83-a72b-059f891e6c2e",
        logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9",
        friendly_id: "vetai",
        subtitle: "Staff Members Only",
        id: "vetai-ujp54",
        emailandpassword: false,
        google: true,
        insurer: false
    }],
    defaultregion: 'gb',
    regions: [{
        api: 'https://api.core.uk.joiipetcare.com',
        code: 'gb',
        flag: '🇬🇧',
        name: 'UK'
    },
    {
        api: 'https://api.core.de.joiipetcare.com',
        code: 'de',
        flag: '🇩🇪',
        name: 'Germany'
    }],
    enableDarkmode: true,
    mixPanel_enabled: false,
    mixpanel_token: "7db80c1e2522cbf23690019ef160b30d",
    activate_trials: false,
    trial_algorithm_category_id: "35cb6e72-8204-4556-86e8-51cb1d230423",
    pms_base_url: ""
};
