import { CommonModule, DatePipe, DecimalPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Injector, Input, OnInit, Optional, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroup, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { DateFormats } from "src/portal/shared/models/enums/date-format.enum";
import { LabelComponent } from "../forms/label/label.component";
import { ValidationComponent } from "../forms/validation/validation.component";

@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetimepicker.component.html',
    styleUrl: './datetimepicker.component.scss',
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          multi: true,
          useExisting: forwardRef(() => DateTimePickerComponent),
        },
        DatePipe
      ],
      standalone: true,
      imports: [NgIf, NgFor, FormsModule, LabelComponent, ValidationComponent, DecimalPipe, CommonModule]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {

  @Input() label!: string;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() name!: string;
  @Input() maximumYears!: number;
  @Input() tooltipMessage!: string;
  @Input() errormessage!: string;
  @Input() form!: FormGroup;
  @Input() currentFormat: DateFormats = DateFormats.DayMonthYear;
  @Output() dateSelection = new EventEmitter<string>();
  
  value!: any;
  errors!: ValidationErrors | null | undefined;
  disabled!: boolean;
  formControl!: NgControl | null;
  dateFormats = DateFormats;
  
  days: number[] = [];
  months: number[] = [];
  years: number[] = [];

  hours: number[]  = [];
  minutes: number[] = [];

  selectedDay!: number;
  selectedMonth!: number;
  selectedYear!: number;

  selectedHour!: number;
  selectedMinutes!: number;

  constructor(
    private datePipe: DatePipe,
    private injector: Injector,
    @Optional() public formDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.days = [];
    this.months = Array.from({ length: 12 }, (_, i) => i + 1);
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: this.maximumYears === undefined ? 100 : this.maximumYears }, (_, i) => currentYear - i);
    this.hours = Array.from({ length: 24 }, (_, i) => i );
    this.minutes = Array.from({ length: 60 }, (_, i) => i);

    const currentDate = new Date();
    this.selectedDay = currentDate.getDate();
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = currentDate.getFullYear();
    this.selectedHour = currentDate.getHours();
    this.selectedMinutes = currentDate.getMinutes();
    this.updateDays();
    this.formControl = this.ngControl;
    if (this.formControl) {
      this.formControl.valueAccessor = this;
    }
  }

  onChange: any = (value: any) => {
  };

  onTouched: any = () => {
  };

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  writeValue(value: any): void {
    if (value) {
      const date = new Date(value);
      this.selectedYear = date.getUTCFullYear();
      this.selectedMonth = date.getUTCMonth() + 1;
      this.selectedDay = date.getUTCDate();
      this.selectedHour = date.getUTCHours();
      this.selectedMinutes = date.getUTCMinutes();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get showDaySelector(): boolean {
    return this.currentFormat === DateFormats.DayMonthYear;
  }
  
  get showMonthSelector(): boolean {
    return this.currentFormat === DateFormats.DayMonthYear|| this.currentFormat === DateFormats.MonthYear;
  }

  updateDays(): void {
    const daysInMonth = this.getDaysInMonth(
      this.selectedYear,
      this.selectedMonth
    );
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay, this.selectedHour, this.selectedMinutes);
    selectedDate.setSeconds(0); // Ensure seconds are set to 00
    this.datePipe.transform(selectedDate, 'yyyy-MM-ddTHH:mm:ss\'Z\'');
    this.dateSelection.emit(
        this.datePipe.transform(selectedDate, 'yyyy-MM-ddTHH:mm:ss\'Z\'') ?? '');
        this.emitSelectedDate();
  }

  private emitSelectedDate(): void {
    const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay, this.selectedHour, this.selectedMinutes);
    selectedDate.setSeconds(0); // Ensure seconds are set to 00
    const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-ddTHH:mm:ss\'Z\'');
    this.onChange(formattedDate); // Update form control value
    this.dateSelection.emit(formattedDate ?? ''); // Emit selected date
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  getDaysInMonth(year: number, month: number): number {
    if (month == 2) {
      return this.isLeapYear(year) ? 29 : 28;
    } else if (['4', '6', '9', '11'].includes(`${month}`)) {
      return 30;
    } else {
      return 31;
    }
  }

}