import { Component, EventEmitter, forwardRef, inject, Input, Output, ViewChild } from '@angular/core';
import { LanguageOption, LanguageService } from './language.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApplyPipe } from 'ngxtension/call-apply';
import { LucideAngularModule } from 'lucide-angular';
import { NgClass } from '@angular/common';
import { NgxFloatUiContentComponent, NgxFloatUiModule } from 'ngx-float-ui';
import { ClickOutside } from 'ngxtension/click-outside';

@Component({
  selector: 'app-language-dropdown',
  standalone: true,
  imports: [ApplyPipe, LucideAngularModule, NgClass, NgxFloatUiModule, ClickOutside],
  templateUrl: './language-dropdown.component.html',
  styleUrl: './language-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LanguageDropdownComponent),
    }
  ]
})
export class LanguageDropdownComponent implements ControlValueAccessor {
  languageService = inject(LanguageService);
  languages = this.languageService.$languages;
  @ViewChild('dropdown', { static: true }) dropdown!: NgxFloatUiContentComponent;

  @Input() value!: string;
  @Input() name!: string;
  @Input() disabled!: boolean;
  @Input() styleClass!: string;

  @Output() change = new EventEmitter<string>();
  
  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  writeValue(value: any, hideDropdown: boolean = false): void {
    this.value = this.value === value ? null : value;
    if (this.dropdown && hideDropdown) {
      this.dropdown?.hide();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.dropdown?.hide();
  }

  onSelect(value: any) {
    if (this.value !== value) {
      this.onChange(value);
      this.onTouched();
    };
    this.writeValue(value, true);
    this.change.emit(this.value);
  }

  getValueName(value: any, options: LanguageOption[]) {
    if (!options || options.length === 0) return undefined;
    return options.find((option) => option.code === value)?.name || 'Select a language';
  }

  getFlag(value: any, options: LanguageOption[]) {
    if (!options || options.length === 0) return undefined;
    return options.find((option) => option.code === value)?.flag || '';
  }
}
