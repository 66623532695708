import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { derivedAsync } from 'ngxtension/derived-async';
import { filter, of, startWith, switchMap, catchError } from 'rxjs';
import { GooglePlacesService } from 'src/shared/services/google-places.service/google-places.service';
import { PartnerPracticeService } from 'src/shared/services/partner-practices.service/partner-practice.service';

@Component({
  selector: "app-prescribing-eligibility-pill",
  standalone: true,
  imports: [],
  templateUrl: './prescribing-eligibility-pill.component.html',
  styleUrl: './prescribing-eligibility-pill.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescribingEligibilityPillComponent {
  googlePlacesService = inject(GooglePlacesService)
  partnerPracticeService = inject(PartnerPracticeService)

  postcode = input<string>("")
  tinyMode = input<boolean>(false)
  partnerPractice = derivedAsync(() => this.getPartnerPractice(this.postcode())) // Computes the partner practice based on the postcode

  private getPartnerPractice(postcode: string) {
    return of(postcode).pipe(
      filter(postcode => postcode != ""),
      switchMap(postcode => this.googlePlacesService.getPartnerPracticeWithin25Miles(postcode)),
      catchError(() => of(undefined)),
      startWith(undefined)
    );
  }
}
