import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { APIError, bookinginternal } from 'src/shared/services/client/client';

import { AppointmentsDataService } from 'src/pms/appointments/services/appointments.data.service/appointments.data.service';
import { ConsultationService } from 'src/shared/services/consultations/consultations.service';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';
import { Subscription, concatMap, interval, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})

export class DashboardComponent implements OnInit, OnDestroy {

  staffProfile: any;
  subscriptions: Subscription = new Subscription();
  paginationConfig: { order_by: string } = { order_by: 'asc' };
  fetchingAppointments: boolean = false;
  fetchingUnAssignedAppointments: boolean = false;
  loadingCreateConsult: boolean = false;
  availableStaffList: Array<any> = [];
  unassignedAppointmentsList: Array<bookinginternal.GetAppointmentResponse> = [];
  appointmentsList: Array<bookinginternal.GetAppointmentResponse> = [];
  appointmentListLastUpdate: Date = new Date();
  unassignedAppointmentListLastUpdate: Date = new Date();
  appointmentListTypeOptions: Array<string> = ['My consults', 'Unassigned', 'All'];
  appointmentListConfig: { page: number, total: number, pageSize: number, pages: number } = { page: 1, total: 0, pageSize: 50, pages: 0 };
  unassignedAppointmentListConfig: { page: number, total: number, pageSize: number, pages: number } = { page: 1, total: 0, pageSize: 50, pages: 0 };
  selectedListType: string = this.appointmentListTypeOptions[0];
  fetchingAllAppointments: boolean = false;
  allAppointmentsList: Array<bookinginternal.GetAppointmentResponse> = [];
  allAppointmentListLastUpdate: Date = new Date();
  allAppointmentListConfig: { page: number, total: number, pageSize: number, pages: number } = { page: 1, total: 0, pageSize: 50, pages: 0 };
  selectedCard = 0;
  isSidebarVisible: boolean = false;
  selectedAppointment?: bookinginternal.GetAppointmentResponse;
  repeatSubscription: Subscription = new Subscription();
  repeatInterval = interval(10000);

  constructor(private router: Router,
    private appointmentsDataService: AppointmentsDataService,
    private consultationService: ConsultationService,
    private staffProfileDataService: StaffProfileDataService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getMyStaffProfile();
  }

  ngOnDestroy(): void {
    this.repeatSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  startRefresh() {
    this.repeatSubscription = this.repeatInterval.subscribe(() => {
      this.refreshAppointments();
    });
  }

  getAvailableStaff() {
    // @ToDo: This will be driven by the API in the future, for now just logged in user
    this.availableStaffList.push(this.staffProfile);
  }

  goToPage(page: string) {
    this.router.navigate([page]);
  }

  handleGetMyStaffProfileError(error: APIError) {
    if (error?.code === "not_found") {
      this.router.navigate(['/onboarding/staff']);
    }
  }

  getMyStaffProfile() {
    this.subscriptions.add(this.staffProfileDataService.GetMyStaffProfile().subscribe((response) => {
      this.staffProfile = response;
      this.getAvailableStaff();
      this.getMyAppointments();
      this.getUnassignedAppointments();
      this.getAllAppointmentsAndSetPayload();
      this.startRefresh();
    },
      (error) => this.handleGetMyStaffProfileError(error)));
  }

  private getMaxDateTimeString(): string {
    let maxDate = new Date();
    maxDate.setHours(new Date().getHours() + 6)
    maxDate.setMinutes(0, 0, 0)
    return maxDate.toISOString().replace('.000Z', '')
  }

  getUnassignedAppointments() {
    const unassignedPayload = {
      RequestedItemsPerPage: this.appointmentListConfig.pageSize,
      RequestedPageNumber: 1,
      BusinessDocID: "",
      EndDate: "",
      StartDate: "",
      Status: ["Booked"],
      ServiceDocID: "",
      OrderBy: this.paginationConfig.order_by,
      PetDocID: "",
      ShiftDocID: "",
      StaffProfileDocID: '',
      // TODO - set this from central service
      Timezone: "Europe/London",
      UserDocID: "",
      Open: false,
      Waiting: false,
      WaitingRoomEntry: true,
      ListAppointmentWithoutStaffProfiles: true,
      ExcludeAppointmentsInConsultationStates: ["Complete", "Disrupted"]
    };
    this.getUnAssignedAppointments(unassignedPayload);
  }


  getAllAppointmentsAndSetPayload() {
    const payload: bookinginternal.ListAppointmentsV2Request = {
      RequestedItemsPerPage: this.allAppointmentListConfig.pageSize,
      RequestedPageNumber: this.allAppointmentListConfig.page,
      BusinessDocID: "",
      EndDate: this.getMaxDateTimeString(),
      StartDate: "",
      Status: ["Booked"],
      ServiceDocID: "",
      OrderBy: this.paginationConfig.order_by,
      PetDocID: "",
      ShiftDocID: "",
      StaffProfileDocID: '',
      // TODO - set this from central service
      Timezone: "Europe/London",
      UserDocID: "",
      Open: false,
      Waiting: false,
      WaitingRoomEntry: true,
      ListAppointmentWithoutStaffProfiles: false,
      ExcludeAppointmentsInConsultationStates: ["Complete", "Disrupted"]
    };
    this.getAllAppointments(payload);
  }

  getMyAppointments() {
    const payload: bookinginternal.ListAppointmentsV2Request = {
      RequestedItemsPerPage: this.appointmentListConfig.pageSize,
      RequestedPageNumber: this.appointmentListConfig.page,
      BusinessDocID: "",
      EndDate: this.getMaxDateTimeString(),
      StartDate: "",
      Status: ["Booked"],
      ServiceDocID: "",
      OrderBy: this.paginationConfig.order_by,
      PetDocID: "",
      ShiftDocID: "",
      StaffProfileDocID: this.staffProfile.id,
      // TODO - set this from central service
      Timezone: "Europe/London",
      UserDocID: "",
      Open: false,
      Waiting: false,
      WaitingRoomEntry: true,
      ListAppointmentWithoutStaffProfiles: false,
      ExcludeAppointmentsInConsultationStates: ["Complete", "Disrupted"]
    };
    this.getAppointments(payload);
  }

  getAppointments(payload: bookinginternal.ListAppointmentsV2Request) {
    this.fetchingAppointments = true;
    this.subscriptions.add(this.appointmentsDataService.ListAppointments(payload).subscribe((response) => {
      this.appointmentsList = response.appointments;
      this.appointmentListConfig.total = response.pagination.total;
      this.appointmentListConfig.pages = response.pagination.pages;
      this.appointmentListLastUpdate = new Date();
      this.fetchingAppointments = false;
    }));
  }

  getUnAssignedAppointments(payload: bookinginternal.ListAppointmentsV2Request) {
    this.fetchingUnAssignedAppointments = true;
    this.subscriptions.add(this.appointmentsDataService.ListAppointments(payload).subscribe((response) => {
      this.unassignedAppointmentsList = response.appointments;
      this.unassignedAppointmentListConfig.total = response.pagination.total;
      this.unassignedAppointmentListConfig.pages = response.pagination.pages
      this.unassignedAppointmentListLastUpdate = new Date();
      this.fetchingUnAssignedAppointments = false;
    }));
  }

  getAllAppointments(payload: bookinginternal.ListAppointmentsV2Request) {
    this.fetchingAllAppointments = true;
    this.subscriptions.add(this.appointmentsDataService.ListAppointments(payload).subscribe((response) => {

      this.allAppointmentsList = response.appointments.sort((a, b) => {
        if (a.pet.triage !== b.pet.triage) {
            return a.pet.triage ? -1 : 1;
        }
        return 0;
    });

      this.allAppointmentListConfig.total = response.pagination.total;
      this.allAppointmentListConfig.pages = response.pagination.pages;
      this.allAppointmentListLastUpdate = new Date();
      this.fetchingAllAppointments = false;
    }));
  }

  refreshAppointments() {
    this.getMyAppointments();
    this.getUnassignedAppointments();
    this.getAllAppointmentsAndSetPayload();
  }

  unAssignAppointmentStaff(appointmentID: string) {
    this.subscriptions.add(this.appointmentsDataService.UnAssignStaffFromAppointment(appointmentID).subscribe({
      next: () => {
        this.refreshAppointments();
      }
    }));
  }

  assignAppointmentStaff(staffID: string, appointmentID: string) {
    this.subscriptions.add(this.appointmentsDataService.AssignStaffToAppointment(staffID, appointmentID).subscribe({
      next: () => {
        this.refreshAppointments();
      }
    }));
  }

  setListType(type: string) {
    this.selectedCard = 0;
    this.selectedListType = type;
  }

  handleAppointmentStaffAssign(event: any, appointmentID: string) {
    switch (event) {
      case 'un-assign':
        this.unAssignAppointmentStaff(appointmentID);
        break;
      default:
        this.assignAppointmentStaff(event, appointmentID);
        break;
    }
  }

  handlePaginationPageUpdate(event: any) {
    this.appointmentListConfig.page = event;
    if (!this.fetchingAppointments) {
      this.getMyAppointments();
    }
  }

  handleUnassignedPageUpdate(event: any) {
    this.unassignedAppointmentListConfig.page = event;
    if (!this.fetchingUnAssignedAppointments) {
      this.getUnassignedAppointments();
    }
  }

  handleUnassignedPageSizeUpdate(event: any) {
    this.unassignedAppointmentListConfig.pageSize = event;
    if (!this.fetchingUnAssignedAppointments) {
      this.getUnassignedAppointments();
    }
  }

  handlePaginationSizeUpdate(event: any) {
    this.appointmentListConfig.pageSize = event;
    if (!this.fetchingAppointments) {
      this.getMyAppointments();
    }
  }

  handleAllPaginationPageUpdate(event: any) {
    this.allAppointmentListConfig.page = event;
    if (!this.fetchingAllAppointments) {
      this.getAllAppointmentsAndSetPayload();
    }
  }

  handleAllPaginationPageSizeUpdate(event: any) {
    this.allAppointmentListConfig.pageSize = event;
    if (!this.fetchingAllAppointments) {
      this.getAllAppointmentsAndSetPayload();
    }
  }

  handleStartConsultation(event: any) {
    this.loadingCreateConsult = true;
    this.subscriptions.add(
      this.consultationService.CreateConsultation({
        appointment_id: event.appointment_id!,
        pet_id: event.pet_id,
        owner_id: event.owner_id,
        notes: ''
      }).pipe(concatMap(resp =>
        this.appointmentsDataService.UpdateAppointmentConsultStatus(event.appointment_id ?? "", {
          consult_status: "Ready",
          disrupted_reason: ""
        }
        ).pipe(map(_ => resp.id)))).subscribe({
        next: resp => {
          this.router.navigate([`/consultation/${resp}`]);
        },
        error: error => {
          this.loadingCreateConsult = false;
          this.toastrService.error(error.message ? error.message : 'Problem creating consultation');
        }
      }));
  }

  handleContinueConsultation(appointment_id: string) {
    this.loadingCreateConsult = true;
    this.subscriptions.add(this.consultationService.GetConsultationIdByAppointment(appointment_id).subscribe({
      next: resp => {
        this.router.navigate([`/consultation/${resp.consultation_doc_id}`]);
      },
      error: error => {
        this.loadingCreateConsult = false;
        this.toastrService.error(error.message ? error.message : 'Problem joining consultation');
      }
    }));
  }

  setMoreDetails(event: any, appointment: bookinginternal.GetAppointmentResponse) {
    this.selectedAppointment = appointment
    this.isSidebarVisible = true;
  }

  onHideSidebar() {
    this.selectedAppointment = undefined;
    this.isSidebarVisible = false;
  }

  appointmentClicked(index: number) {
    this.selectedCard = index;
  }

}
