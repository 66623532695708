<header class="bg-white shadow-sm relative">
  @if(showDefault){
  <!--Default header-->
  <nav class="mx-auto flex max-w-8xl items-center justify-between p-5 lg:px-8 z-10" aria-label="Global">
    <a (click)="logoClick()" class="-m-1.5 p-1.5 w-auto cursor-pointer flex justify-between items-center"
      [ngClass]="{'mx-auto': !showSignInButtons}">
      <img class="flex-1 max-h-8 w-full max-w-full px-1" [src]="config.logo" alt="">
      @if(tenant && tenant.insurer){
      <!--Logos-->
      <div class="flex-1 flex justify-center align-items-center max-h-6 max-w-full w-full px-3">
        <svg width="2" height="34" viewBox="0 0 2 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Line 59" d="M1 1L1 33" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <img class="flex-1 max-h-8 max-w-full w-full px-1" [src]="tenant.logo" alt="">
      }
    </a>
    @if(showSignInButtons){
    <!--buttons for unsigned users-->
    <div class="lg:flex lg:gap-x-12 hidden lg:block">
      <a (click)="register()" class="cursor-pointer font-semibold leading-6 text-gray-900">Create an
        account</a>
      <a (click)="login()" class="cursor-pointer font-semibold leading-6 text-gray-900">Login</a>
    </div>
    }
  </nav>
  @if(showProggressBar){
  <app-progress-bar [progressPercentage]="progressPercentage"></app-progress-bar>
  }
  }
  @else{
  <!--Dashboard header-->
  <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 px-8 xl:px-0 relative" aria-label="Global">
    <div class="flex justify-between">
      <div class="flex lg:hidden me-3">
        <button (click)="toggle()" type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span class="sr-only">Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
      <!--Logos-->
      <a (click)="logoClick()" class="-m-1.5 p-1.5 cursor-pointer flex justify-between items-center">
        <img class="flex-1 max-h-8 w-full max-w-full px-1" [src]="config.logo" alt="">
        @if(tenant && tenant.insurer){
        <div class="flex-1 flex justify-center align-items-center max-h-6 max-w-full w-full px-3">
          <svg width="2" height="34" viewBox="0 0 2 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Line 59" d="M1 1L1 33" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
          </svg>
        </div>
        <img class="flex-1 max-h-8 max-w-full w-full px-1" [src]="tenant.logo" alt="">
        }
      </a>
    </div>
    <div class="flex justify-between relative items-center">
      <div class="hidden lg:flex lg:gap-x-9 items-center">
        @for(item of config.items; track item; let i = $index){
        @if(item.environments == 'all' || !isProduction){

        @if(item.state != 'mobile' && (item?.hidden ? !item?.hidden() : true)){
        <!--DashboardButtons-->
        <a (click)="menuItemClicked(item, item.index)" class="cursor-pointer font-semibold leading-6 text-gray-900"
          [ngStyle]="{'color': isActiveButtonIndex(item.index) ? '#009CB8' : 'inherit'}">{{item.title}}</a>
        }
        }
        }
      </div>
      @if(showNotificationBell) {
      <a class="cursor-pointer lg:ms-10 mt-0.5" (click)="toggleNotificationBell()">
        <ng-icon class="flex items-center justify-center h-full" [size]="'24px'"
          [name]="showNotificationDropdown ? 'ionNotifications' : 'ionNotificationsOutline'"></ng-icon>
      </a>
      <span [ngClass]="{'hidden': !showNotificationRedDot}"
        class="absolute -top-0.5 -right-0.5 h-3 w-3 border border-white border-2 bg-red-500 rounded-full"></span>
      }
      @if(showLanguageDropdown()) {
        <app-language-dropdown class="block ml-9" [(ngModel)]="languageCode" (change)="onLanguageChange($event)"></app-language-dropdown>
      }
    </div>
  </nav>
  <div [ngClass]="{'hidden': !showNotificationDropdown}"
    class="notification-dropdown-feed absolute right-0 w-full md:w-5/12 lg:w-4/12 bg-white rounded-bl-md rounded-br-md shadow-lg z-5">
    @if(!showNotificationNoMessages) {
    <app-brazecontentcardfeed [brazeKey]="'inbox_feed'"></app-brazecontentcardfeed>
    } @else {
    <div class="w-full flex justify-center">
      <p class="mt-4">No updates, please check again later</p>
    </div>
    }
  </div>
  @if(showMenu){
  <!--Menu-->
  <div class="lg:hidden" role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div
      class="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">

      <div class="flex item-center justify-between">
        <a (click)="logoClick()" class="-m-1.5 p-1.5 cursor-pointer flex justify-between items-center">
          <img class="flex-1 max-h-8 w-full max-w-full px-1" [src]="config.logo" alt="">
          @if(tenant && tenant.insurer){
          <div class="flex-1 flex justify-center align-items-center max-h-6 max-w-full w-full px-3">
            <svg width="2" height="34" viewBox="0 0 2 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Line 59" d="M1 1L1 33" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
          <img class="flex-1 max-h-8 max-w-full w-full px-1" [src]="tenant.logo" alt="">
          }
        </a>
        <button (click)="toggle()" type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700 ms-2">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            @for(item of config.items; track item; let i = $index){
            @if(item.environments == 'all' || !isProduction){
            @if(i == 4){
            <div class="w-full h-px bg-gray-200 rounded-full"></div>
            }
            @if(item.state != 'desktop' && (item?.hidden ? !item?.hidden() : true)){
            @if(item.type == 'dropdown'){
            <div [class]="item.id">
              <a (click)="menuItemClicked(item, item.index)"
                [ngStyle]="{'color': isDropdownOpen[item.id] ? '#009CB8' : 'inherit'}"
                class="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex justify-between items-center">
                {{ item.title }}
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transform"
                  [ngClass]="{'rotate-180': isDropdownOpen[item.id]}" viewBox="0 0 24 24" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </a>
              <div [ngClass]="{'hidden': !isDropdownOpen[item.id]}">
                @for(subItem of item.subItems; track subItem; let y = $index) {
                @if(subItem.environments == 'all' || !isProduction){
                <a (click)="menuItemClicked(subItem, subItem.index)"
                  [ngStyle]="{'color': isActiveButtonIndex(subItem.index) ? '#009CB8' : 'inherit'}"
                  class="cursor-pointer -mx-3 block rounded-lg px-3 py-2 ml-4 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  {{subItem.title}}
                </a>
                }
                }
              </div>
            </div>
            }
            @else{
            <a (click)="menuItemClicked(item, item.index)"
              [ngStyle]="{'color': isActiveButtonIndex(item.index) ? '#009CB8' : 'inherit'}"
              class="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
              {{item.title}}
            </a>
            }
            }
            }
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  }
  }
</header>

<section class="relative" [class.hidden]="!showDownloadApp">
  <div class="w-full h-full fixed top-0 left-0 z-30 bg-black opacity-30"></div>
  <article class="w-80 h-[520px] fixed bg-white z-40 p-10 rounded inset-0 mx-auto my-auto rounded-[10px]">
    <div
      class="w-8 h-8 bg-neutral-100 rounded-full flex justify-center items-center absolute top-3 right-3 cursor-pointer"
      (click)="handleIsMobile()">
      <ng-icon name="ionCloseSharp" color="#207EA7" size="20px"></ng-icon>
    </div>
    <div class="grid grid-cols-1 place-items-center">
      <div class="text-center text-neutral-800 text-md font-medium tracking-wide">Get the best experience</div>
      <div class="w-[250px] h-[250px] justify-center items-center inline-flex mt-2">
        <img class="w-[250px] h-[250px]"
          src="https://firebasestorage.googleapis.com/v0/b/core-6p22-ukprod-branding-assets/o/download-app.png?alt=media&token=6b814b7a-05a1-4f21-87ff-18434bad2412" />
      </div>
      <div class="mt-3 text-center text-neutral-800 text-sm font-normal tracking-tight">Enjoy a smoother experience with
        our app. Download now to speak with a vet.</div>
      <a class="w-full max-w-[296px] mt-8" [href]="'https://joii.onelink.me/aZ59/bgbjimsd'"><button
          class="w-full joii-btn-primary inline-flex items-center">Get the app</button></a>
    </div>
  </article>
</section>