import { inject, Injectable, signal } from '@angular/core';
import { RemoteConfig, fetchAndActivate, getBoolean, getString } from '@angular/fire/remote-config';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  remoteConfig = inject(RemoteConfig);
  configurations = signal<Map<string, any>>(new Map());
  initialized = signal<boolean>(false);

  // Firebase values
  getStringValue = getString; 
  getBooleanValue = getBoolean;
  remoteConfigFetchAndActivate = fetchAndActivate;

  /**
   * Sets the configurations from the remote firebase config.
   * @returns {void}
   */
  setConfigurations(): void {
    this.configurations.set(new Map<string, any>([
      ['ab_test_web_cta_sc_vs_callvet', this.getStringValue(this.remoteConfig, 'ab_test_web_cta_sc_vs_callvet')],
      ['markerstudy_symptoms_visible', this.getBooleanValue(this.remoteConfig, 'markerstudy_symptoms_visible')]
    ]));
  }

  /**
   * Fetches and activates the remote firebase config.
   * @returns {Observable<boolean>} 
   * Where true is returned when the remote config is fetched
   * and false is return when the cache is used.
   */
  fetchAndActivate():Observable<boolean> {
    return from(this.remoteConfigFetchAndActivate(this.remoteConfig)).pipe(
      map(result => {
        this.setConfigurations();
        this.initialized.set(true);
        return result;
      })
    );
  }
}
