<div class="relative">
    <button #Dropdown [id]="name" [name]="name" [disabled]="disabled"
    class="max-w-64 h-[38px] px-3 py-2.5 bg-blue-900 rounded-lg justify-center items-center gap-2 inline-flex"
    [ngClass]="styleClass"
    [class.disabled]="disabled"
    [floatUiLoose]="dropdown"
    [loosePlacement]="'bottom-end'"
    applyClass="language-dropdown"
    trigger="click"
    [preventOverflow]="true"
    data-automationid="language-dropdown"
    >
    <span>{{getFlag | apply: value: languages()}}</span>
    <span class="text-center text-white text-[15px] font-normal font-['Inter'] tracking-tight truncate">{{getValueName | apply: value: languages()}}</span>
    <lucide-angular name="chevron-down" size="16" class="text-white"></lucide-angular>
</button>
</div>

<float-ui-content #dropdown>
    <div class="custom-select-options">
        @for (option of languages(); track $index) {
          <div class="custom-select-option" [tabIndex]="0" (keydown.Enter)="onSelect(option.code)" (click)="onSelect(option.code)" data-automationid="language-dropdown-option">
            <div class="flex items-center gap-[14px]">
                <span class="">{{option.flag}}</span>
                <span class="text-center text-neutral-800 text-[15px] font-semibold font-['Inter'] tracking-tight truncate">{{ option.name }}</span> 
            </div>
            @if(option.code === value) {<lucide-angular class="text-blue-500 shrink-0" [size]="16"  name="check"></lucide-angular>}
          </div>
        }
      </div>
</float-ui-content>

