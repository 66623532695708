@if(partnerPractice()){
@if (tinyMode()) {
<div class="w-2 h-2 bg-cool-blue rounded-full inline-block"></div>
} @else {
<div class="h-7 justify-start items-start inline-flex">
    <div class="p-1.5  bg-cool-blue rounded-[999px] justify-start items-center gap-px flex">
        <div class="px-1 justify-start items-center gap-[7px] flex">
            <div class="text-white text-xs leading-none tracking-tight">Eligible for
                prescriptions</div>
        </div>
    </div>
</div>
}
}
