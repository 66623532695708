import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/auth_service';
import { CookieService } from '../services/cookies/cookie_service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private cookieService: CookieService, private authService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): boolean {

    const currentUrl = state.url;

    if (!this.cookieService.getCookie("loggedIn")) {
      this.router.navigate(['/login'], { 
        queryParams: { redirectTo: `${currentUrl}`} 
      });
      return false;
    }
    this.authService.setSentryUser();
    return true;
  }
}