<header>
  <div class="flex flex-row-reverse align-middle">
    <button class="joii-btn-outline ml-2" (click)="goToPage('/account/staffprofile')">Profile</button>
    <button class="joii-btn-outline" (click)="goToPage('/videocheck')">Test audio/video</button>
    <app-dark-mode-toggle></app-dark-mode-toggle>
  </div>
  <p>Welcome back<span *ngIf="staffProfile" [title]="staffProfile.name">&nbsp;{{staffProfile.name | staffName}}</span>
  </p>
  <app-joii-offline-check></app-joii-offline-check>
  <div class="flex flex-row">
    <app-title title="Upcoming Consults"></app-title>
    <app-staffonlineview view="photos"></app-staffonlineview>
  </div>
  <nav class="-mb-px flex space-x-8">
    <a (click)="setListType(appointmentListTypeOptions[0])"
      class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
      [ngClass]="{'border-blue-900 dark:border-white joii-text-primary': selectedListType === appointmentListTypeOptions[0], 'border-transparent text-gray-500 dark:text-white/opacity-70 hover:border-gray-300 hover:text-gray-700 hover:dark:text-white': selectedListType !== appointmentListTypeOptions[0]}">
      <span>My Consults&nbsp;</span>
      <span *ngIf="appointmentsList"
        class="rounded-full inline-block w-6 h-6 text-center pt-0.5 text-white dark:text-primary"
        [ngClass]="{'joii-bg-primary': selectedListType === appointmentListTypeOptions[0], 'bg-gray-500': selectedListType !== appointmentListTypeOptions[0]}">{{appointmentListConfig.total}}</span>
    </a>
    <a (click)="setListType(appointmentListTypeOptions[1])"
      class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
      [ngClass]="{'border-blue-900 dark:border-white joii-text-primary': selectedListType === appointmentListTypeOptions[1], 'border-transparent text-gray-500 dark:text-white/opacity-70 hover:border-gray-300 hover:text-gray-700 hover:dark:text-white': selectedListType !== appointmentListTypeOptions[1]}">
      <span>Unassigned&nbsp;</span>
      <span *ngIf="unassignedAppointmentsList"
        class="rounded-full inline-block w-6 h-6 text-center pt-0.5 text-white dark:text-primary"
        [ngClass]="{'joii-bg-primary': selectedListType === appointmentListTypeOptions[1], 'bg-gray-500': selectedListType !== appointmentListTypeOptions[1]}">{{unassignedAppointmentListConfig.total}}</span>
    </a>
    <a (click)="setListType(appointmentListTypeOptions[2])"
      class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
      [ngClass]="{'border-blue-900 dark:border-white joii-text-primary': selectedListType === appointmentListTypeOptions[2], 'border-transparent text-gray-500 dark:text-white/opacity-70 hover:border-gray-300 hover:text-gray-700 hover:dark:text-white': selectedListType !== appointmentListTypeOptions[2]}">
      <span>All&nbsp;</span>
      <span *ngIf="allAppointmentsList"
        class="rounded-full inline-block w-6 h-6 text-center pt-0.5 text-white dark:text-primary"
        [ngClass]="{'joii-bg-primary': selectedListType === appointmentListTypeOptions[2], 'bg-gray-500': selectedListType !== appointmentListTypeOptions[2]}">{{allAppointmentListConfig.total}}</span>
    </a>
  </nav>
</header>
<p class="mt-0.5"
  *ngIf="appointmentsList && appointmentListLastUpdate && selectedListType === appointmentListTypeOptions[0]">
  <small>Last updated: {{appointmentListLastUpdate | date: 'medium'}}</small>
</p>
<section *ngIf="appointmentsList.length > 0 && selectedListType === appointmentListTypeOptions[0]">
  <article *ngFor="let appointment of appointmentsList; index as i" data-automationid="dashboard-appointment-card">
    <app-appointment-card [expanded]="i === selectedCard" [appointmentDetails]="appointment"
      [staffProfile]="staffProfile" [availableStaff]="availableStaffList" [loadingCreateConsult]="loadingCreateConsult"
      (startConsultation)="handleStartConsultation($event)" (continueConsultation)="handleContinueConsultation($event)"
      (assignStaff)="handleAppointmentStaffAssign($event, appointment.doc_id)"
      (moreDetailsClicked)="setMoreDetails($event, appointment)"
      (onToggleExpanded)="appointmentClicked(i)"></app-appointment-card>
  </article>
  <footer *ngIf="appointmentsList.length > 0">
    <app-pagination [pageSize]="appointmentListConfig.pageSize" [totalPagesCount]="appointmentListConfig.pages"
      (pageSizeChange)="handlePaginationSizeUpdate($event)"
      (currentPageChange)="handlePaginationPageUpdate($event)"></app-pagination>
  </footer>
</section>
<section *ngIf="appointmentsList.length === 0 && selectedListType === appointmentListTypeOptions[0]">
  <p>No upcoming appointments</p>
</section>
<p class="mt-0.5"
  *ngIf="unassignedAppointmentsList && unassignedAppointmentListLastUpdate && selectedListType === appointmentListTypeOptions[1]">
  <small>Last updated: {{unassignedAppointmentListLastUpdate | date: 'medium'}}</small>
</p>
<section *ngIf="unassignedAppointmentsList.length > 0 && selectedListType === appointmentListTypeOptions[1]">
  <article *ngFor="let appointment of unassignedAppointmentsList; index as i">
    <app-appointment-card [expanded]="i === selectedCard" [appointmentDetails]="appointment"
      [staffProfile]="staffProfile" [availableStaff]="availableStaffList"
      (startConsultation)="handleStartConsultation($event)" (continueConsultation)="handleContinueConsultation($event)"
      (assignStaff)="handleAppointmentStaffAssign($event, appointment.doc_id)"
      (moreDetailsClicked)="setMoreDetails($event, appointment)"
      (onToggleExpanded)="appointmentClicked(i)"></app-appointment-card>
  </article>
  <footer *ngIf="unassignedAppointmentsList.length > 0">
    <app-pagination [pageSize]="unassignedAppointmentListConfig.pageSize"
      [totalPagesCount]="unassignedAppointmentListConfig.pages"
      (pageSizeChange)="handleUnassignedPageSizeUpdate($event)"
      (currentPageChange)="handleUnassignedPageUpdate($event)"></app-pagination>
  </footer>
</section>
<section *ngIf="unassignedAppointmentsList.length === 0 && selectedListType === appointmentListTypeOptions[1]">
  <p>No upcoming appointments</p>
</section>

<p class="mt-0.5"
  *ngIf="allAppointmentListConfig && allAppointmentListLastUpdate && selectedListType === appointmentListTypeOptions[2]">
  <small>Last updated: {{allAppointmentListLastUpdate | date: 'medium'}}</small>
</p>
<section *ngIf="allAppointmentsList.length > 0 && selectedListType === appointmentListTypeOptions[2]">
  <article *ngFor="let appointment of allAppointmentsList; index as i">
    <app-appointment-card [expanded]="i === selectedCard" [appointmentDetails]="appointment"
      [staffProfile]="staffProfile" [availableStaff]="availableStaffList"
      (startConsultation)="handleStartConsultation($event)" (continueConsultation)="handleContinueConsultation($event)"
      (assignStaff)="handleAppointmentStaffAssign($event, appointment.doc_id)"
      (moreDetailsClicked)="setMoreDetails($event, appointment)"
      (onToggleExpanded)="appointmentClicked(i)"></app-appointment-card>
  </article>
  <footer *ngIf="allAppointmentsList.length > 0">
    <app-pagination [pageSize]="allAppointmentListConfig.pageSize" [totalPagesCount]="allAppointmentListConfig.pages"
      (pageSizeChange)="handleAllPaginationPageSizeUpdate($event)"
      (currentPageChange)="handleAllPaginationPageUpdate($event)"></app-pagination>
  </footer>
</section>
<section *ngIf="allAppointmentsList.length === 0 && selectedListType === appointmentListTypeOptions[2]">
  <p>No upcoming appointments</p>
</section>
<app-more-details-sidebar #moreDetailsSidebar [appointmentDetails]="selectedAppointment" (hideSidebar)="onHideSidebar()"
  (startConsult)="handleStartConsultation({ pet_id: selectedAppointment!.pet_id, owner_id: selectedAppointment!.user_id, appointment_id: selectedAppointment!.doc_id})"
  (continueConsult)="handleContinueConsultation(selectedAppointment!.doc_id)"
  [staffProfile]="staffProfile"></app-more-details-sidebar>
<app-image-carousel-modal></app-image-carousel-modal>
